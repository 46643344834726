export const hasIntersectionObserver = () => 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window;

export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

// samsungs smart tv
export const isTizen = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('tizen') > -1;
};
