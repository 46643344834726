import {shouldPolyfill as shouldCanonicalsPolyfill} from '@formatjs/intl-getcanonicallocales/should-polyfill';
import {shouldPolyfill as shouldLocalePolyfill} from '@formatjs/intl-locale/should-polyfill';
import {shouldPolyfill as shouldPluralRulesPolyfill} from '@formatjs/intl-pluralrules/should-polyfill';
import {shouldPolyfill as shouldNFPolyfill} from '@formatjs/intl-numberformat/should-polyfill';
import {shouldPolyfill as shouldDFPolyfill} from '@formatjs/intl-datetimeformat/should-polyfill';
import {shouldPolyfill as shouldRTFPolyfill} from '@formatjs/intl-relativetimeformat/should-polyfill';

/* add required i18n polyfills here */
const polyfillsIntl = (): Promise<void>[] => {
    const fillIntl = new Promise<void>(resolve => {
        if ('Intl' in window) {
            return resolve();
        }

        import(/* webpackChunkName: "intl" */ 'intl').then(() => resolve());
    });

    const fillIntlCanonicalLocales = new Promise<void>(resolve => {
        fillIntl.then(() => {
            if (!shouldCanonicalsPolyfill()) {
                return resolve();
            }

            import(/* webpackChunkName: "intl-canonical-locales" */ '@formatjs/intl-getcanonicallocales/polyfill').then(
                () => resolve(),
            );
        });
    });

    const fillIntlLocale = new Promise<void>(resolve => {
        fillIntlCanonicalLocales.then(() => {
            if (!shouldLocalePolyfill()) {
                return resolve();
            }

            import(/* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill').then(() => resolve());
        });
    });

    const fillIntlPluralRules = new Promise<void>(resolve => {
        fillIntlLocale.then(() => {
            if (!shouldPluralRulesPolyfill()) {
                return resolve();
            }

            import(/* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/polyfill').then(() => {
                import(
                    /* webpackChunkName: "intl-pluralrules-locale-de" */ '@formatjs/intl-pluralrules/locale-data/en'
                ).then(() => {
                    import(
                        /* webpackChunkName: "intl-pluralrules-locale-en" */ '@formatjs/intl-pluralrules/locale-data/de'
                    ).then(() => resolve());
                });
            });
        });
    });

    const fillIntlNumberFormat = new Promise<void>(resolve => {
        fillIntlPluralRules.then(() => {
            if (!shouldNFPolyfill()) {
                return resolve();
            }

            import(/* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/polyfill').then(() => {
                import(
                    /* webpackChunkName: "intl-numberformat-en" */ '@formatjs/intl-numberformat/locale-data/en'
                ).then(() => {
                    import(
                        /* webpackChunkName: "intl-numberformat-de" */ '@formatjs/intl-numberformat/locale-data/de'
                    ).then(() => resolve());
                });
            });
        });
    });

    const fillIntlDateTimeFormat = new Promise<void>(resolve => {
        fillIntlNumberFormat.then(() => {
            if (!shouldDFPolyfill()) {
                return resolve();
            }

            import(/* webpackChunkName: "intl-dateformat" */ '@formatjs/intl-datetimeformat/polyfill').then(() => {
                import(
                    /* webpackChunkName: "intl-dateformat-en" */ '@formatjs/intl-datetimeformat/locale-data/en'
                ).then(() => {
                    import(
                        /* webpackChunkName: "intl-dateformat-de" */ '@formatjs/intl-datetimeformat/locale-data/de'
                    ).then(() => resolve());
                });
            });
        });
    });

    const fillIntlRelativeTimeFormat = new Promise<void>(resolve => {
        fillIntlNumberFormat.then(() => {
            if (!shouldRTFPolyfill()) {
                return resolve();
            }

            import(
                /* webpackChunkName: "intl-relative-time-format" */ '@formatjs/intl-relativetimeformat/polyfill'
            ).then(() => {
                import(
                    /* webpackChunkName: "intl-relative-time-format-en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
                ).then(() => {
                    import(
                        /* webpackChunkName: "intl-relative-time-format-de" */ '@formatjs/intl-relativetimeformat/locale-data/de'
                    ).then(() => resolve());
                });
            });
        });
    });

    return [
        fillIntl,
        fillIntlCanonicalLocales,
        fillIntlLocale,
        fillIntlPluralRules,
        fillIntlNumberFormat,
        fillIntlRelativeTimeFormat,
        fillIntlDateTimeFormat,
    ];
};

export default polyfillsIntl;
