import {polyfills} from './shared/polyfills';

import(/* webpackChunkName: "SentryLogger" */ './shared/sentry/SentryLogger').then(module => {
    module.initSentry();
});

Promise.all(polyfills).then(() => {
    import(/* webpackChunkName: "App" */ './shared/redux/initializeStore').then(() => {
        import(/* webpackChunkName: "CloudWatchLogger" */ './shared/cloudWatchLogger/CloudWatchLogger').then(module => {
            module.CloudWatchLogger.init();
            import(/* webpackChunkName: "WindowLogger" */ './shared/logger/LoggerEntryWindowOnError').then(module =>
                module.initWindowOnErrorListener(),
            );
        });
    });
});
