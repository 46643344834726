import {isTizen} from '../../utils/supports';

const polyfillsCoreJS: Promise<void>[] = [];

/* heads up: why not to use 'in' with arrays: https://rules.sonarsource.com/typescript/RSPEC-4619*/
if (
    !('startsWith' in String.prototype) &&
    !('endsWith' in String.prototype) &&
    !('assign' in Object) &&
    !('keys' in Object) &&
    !('Map' in window) &&
    !('Set' in window) &&
    !Array.prototype.includes
) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "CoreJS" */ 'core-js'));
}

if (!('values' in Object)) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "ObjectValues" */ 'core-js/modules/es.object.values'));
}

if (!Array.prototype.flat) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "ArrayFlat" */ 'core-js/modules/es.array.flat'));
}

if (!Array.prototype.flatMap) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "ArrayFlatMap" */ 'core-js/modules/es.array.flat-map'));
}

if (!('URLSearchParams' in window)) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "URLSearchParams" */ 'core-js/modules/web.url-search-params'));
}

if (!Promise.prototype.finally) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "PromiseFinally" */ 'core-js/modules/es.promise.finally'));
}

// for smartTV
if (isTizen()) {
    polyfillsCoreJS.push(import(/* webpackChunkName: "core-js" */ 'core-js'));
}

export default polyfillsCoreJS;
